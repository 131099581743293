import { mapIncomePeriodValues } from '@pretto/app/src/Sentences/Income/Salary/lib/mappers/mapIncomePeriod'
import { mapIncomeTypeValues } from '@pretto/app/src/Sentences/Income/Salary/lib/mappers/mapIncomeType'
import { Choices } from '@pretto/app/src/Sentences/components/Styled/Choices/Choices'
import { Message } from '@pretto/app/src/Sentences/components/Styled/Message/Message'
import { Prompt } from '@pretto/app/src/Sentences/components/Styled/Prompt/Prompt'
import { Title } from '@pretto/app/src/Sentences/components/Styled/Title/Title'
import { EventName } from '@pretto/app/src/Sentences/config/tracking/events'
import { ButtonNext } from '@pretto/app/src/Sentences/containers/ButtonNext/ButtonNext'
import { TrackedResizableField } from '@pretto/app/src/Sentences/containers/TrackedResizableField/TrackedResizableField'
import { TrackedView } from '@pretto/app/src/Sentences/containers/TrackedView/TrackedView'
import { useRoutesGraph } from '@pretto/app/src/Sentences/contexts/RoutesGraphContext'
import { useSentences } from '@pretto/app/src/Sentences/contexts/SentencesContext'
import { toIntOrNull } from '@pretto/app/src/Sentences/lib/toIntOrNull'
import { useActiveField } from '@pretto/app/src/Sentences/lib/useActiveField'
import { t } from '@pretto/app/src/lib/i18n'
import {
  SimulationMortgagorIncomePeriod,
  SimulationMortgagorIncomeTaxes,
  SimulationMortgagorOccupationKind,
  SimulationMortgagorOccupationOtherStatus,
} from '@pretto/app/src/types/api/enums'

export const SalaryPage: React.FC = () => {
  const { simulation, setSimulationMortgagor } = useSentences()
  const { nextRoute } = useRoutesGraph()
  const mainMortgagor = simulation.profileMortgagors[0]
  const isBusinessOwner =
    mainMortgagor?.occupationOtherStatus === SimulationMortgagorOccupationOtherStatus.BusinessOwner
  const isIncomeSalaryPeriodMonthly = mainMortgagor?.incomeSalaryPeriod === SimulationMortgagorIncomePeriod.Monthly
  const getDefaultActiveField = () => {
    if (!mainMortgagor?.incomeSalaryPeriod) return 0
    if (!mainMortgagor?.incomeSalaryTaxes) return 1
    return 2
  }

  const getIsFilled = () =>
    Boolean(
      nextRoute &&
        mainMortgagor?.incomeSalaryPeriod &&
        mainMortgagor?.incomeSalaryTaxes &&
        mainMortgagor?.incomeSalaryAmount
    )

  const [activeField, setActiveField] = useActiveField(getDefaultActiveField(), getIsFilled())

  if (!simulation.profileMortgagors || !simulation.profileMortgagors.length) {
    return null
  }

  const saveAmount = (value: string) => {
    setSimulationMortgagor({ incomeSalaryAmount: toIntOrNull(value) })
  }

  const isSalaryHigherThan25K = (salary: number | null) => {
    if (!salary) return false
    return salary > 25_000
  }

  return (
    <TrackedView events={{ eventName: EventName.PageViewed, eventPayload: { page: 'salary' } }}>
      <Title>{t('sentences.income.title')}</Title>
      <div>
        {mainMortgagor?.occupationKind === SimulationMortgagorOccupationKind.Other ||
        mainMortgagor?.occupationKind === SimulationMortgagorOccupationKind.Medical
          ? t('sentences.income.otherIncome.sentence.0')
          : t('sentences.income.salary.sentence.0')}
        <Prompt onClick={() => setActiveField(0)} isFilled={!!mainMortgagor?.incomeSalaryPeriod}>
          {t(`sentences.income.period.${mainMortgagor?.incomeSalaryPeriod || SimulationMortgagorIncomePeriod.Yearly}`)}
        </Prompt>
        {activeField > 0 && (
          <Prompt onClick={() => setActiveField(1)} isFilled={!!mainMortgagor?.incomeSalaryTaxes}>
            {t(`sentences.income.type.${mainMortgagor?.incomeSalaryTaxes || SimulationMortgagorIncomeTaxes.After}`)}
          </Prompt>
        )}
        {activeField > 1 && (
          <>
            {mainMortgagor?.occupationKind === SimulationMortgagorOccupationKind.Other ||
            mainMortgagor?.occupationKind === SimulationMortgagorOccupationKind.Medical ? (
              <>
                {t('sentences.income.otherIncome.sentence.1')}
                {mainMortgagor?.occupationOtherStatus === SimulationMortgagorOccupationOtherStatus.BusinessOwner &&
                  t('sentences.income.otherIncome.sentence.2')}
                {t('sentences.income.otherIncome.sentence.3')}
              </>
            ) : (
              t('sentences.income.salary.sentence.1')
            )}
            <TrackedResizableField
              clearable
              events={{ eventName: EventName.SimulationFieldChanged, eventPayload: { field: 'incomeSalaryAmount' } }}
              inputProps={{
                autoFocus: activeField === 2,
                placeholder:
                  mainMortgagor?.incomeSalaryPeriod === SimulationMortgagorIncomePeriod.Yearly ? '22000' : '1800',
                type: 'integer',
              }}
              onChange={saveAmount}
              value={mainMortgagor?.incomeSalaryAmount?.toString() || ''}
            />
            €
          </>
        )}
        .
      </div>

      {activeField === 0 && (
        <Choices
          choices={mapIncomePeriodValues(
            Object.values([SimulationMortgagorIncomePeriod.Yearly, SimulationMortgagorIncomePeriod.Monthly])
          )}
          events={{ eventName: EventName.SimulationChoiceClicked, eventPayload: { choice: 'salaryIncomePeriod' } }}
          onChoiceClick={() => setActiveField(1)}
        />
      )}

      {activeField === 1 && (
        <Choices
          choices={mapIncomeTypeValues(
            Object.values([SimulationMortgagorIncomeTaxes.After, SimulationMortgagorIncomeTaxes.Before])
          )}
          events={{ eventName: EventName.SimulationChoiceClicked, eventPayload: { choice: 'salaryIncomeType' } }}
          onChoiceClick={() => setActiveField(2)}
        />
      )}

      {activeField > 1 &&
        isIncomeSalaryPeriodMonthly &&
        isSalaryHigherThan25K(mainMortgagor?.incomeSalaryAmount ?? null) && (
          <Message
            html={t('sentences.tips.income.salary.amount.mortgagor', {
              annualSalary: ((mainMortgagor?.incomeSalaryAmount ?? 0) * 12).toLocaleString('fr'),
            })}
          />
        )}

      {isBusinessOwner && <Message>{t('sentences.tips.income.businessOwner.mortgagor')}</Message>}

      {activeField > 1 && mainMortgagor.incomeSalaryTaxes === SimulationMortgagorIncomeTaxes.After && (
        <Message html={t('sentences.tips.income.salary.tax.mortgagor')} />
      )}

      {activeField >= 2 && !!mainMortgagor.incomeSalaryAmount && <ButtonNext>{t('sentences.next')}</ButtonNext>}
    </TrackedView>
  )
}
