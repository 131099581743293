import { mapIncomePeriodValues } from '@pretto/app/src/Sentences/ComortgagorIncome/Salary/lib/mappers/mapIncomePeriod'
import { mapIncomeTypeValues } from '@pretto/app/src/Sentences/ComortgagorIncome/Salary/lib/mappers/mapIncomeType'
import { Choices } from '@pretto/app/src/Sentences/components/Styled/Choices/Choices'
import { Message } from '@pretto/app/src/Sentences/components/Styled/Message/Message'
import { Prompt } from '@pretto/app/src/Sentences/components/Styled/Prompt/Prompt'
import { Title } from '@pretto/app/src/Sentences/components/Styled/Title/Title'
import { EventName } from '@pretto/app/src/Sentences/config/tracking/events'
import { ButtonNext } from '@pretto/app/src/Sentences/containers/ButtonNext/ButtonNext'
import { TrackedResizableField } from '@pretto/app/src/Sentences/containers/TrackedResizableField/TrackedResizableField'
import { TrackedView } from '@pretto/app/src/Sentences/containers/TrackedView/TrackedView'
import { useRoutesGraph } from '@pretto/app/src/Sentences/contexts/RoutesGraphContext'
import { useSentences } from '@pretto/app/src/Sentences/contexts/SentencesContext'
import { toIntOrNull } from '@pretto/app/src/Sentences/lib/toIntOrNull'
import { useActiveField } from '@pretto/app/src/Sentences/lib/useActiveField'
import { t } from '@pretto/app/src/lib/i18n'
import {
  SimulationMortgagorIncomePeriod,
  SimulationMortgagorIncomeTaxes,
  SimulationMortgagorOccupationKind,
  SimulationMortgagorOccupationOtherStatus,
} from '@pretto/app/src/types/api/enums'

export const SalaryPage: React.FC = () => {
  const context = useSentences()
  const { simulation, setSimulationComortgagor } = context

  const { nextRoute } = useRoutesGraph()

  const isBusinessOwner =
    simulation.profileMortgagors[1]?.occupationOtherStatus === SimulationMortgagorOccupationOtherStatus.BusinessOwner
  const isIncomeSalaryPeriodMonthly =
    simulation.profileMortgagors[1]?.incomeSalaryPeriod === SimulationMortgagorIncomePeriod.Monthly
  const getDefaultActiveField = () => {
    if (!simulation.profileMortgagors[1]?.incomeSalaryPeriod) return 0
    if (!simulation.profileMortgagors[1]?.incomeSalaryTaxes) return 1
    return 2
  }

  const getIsFilled = () =>
    Boolean(
      nextRoute &&
        simulation.profileMortgagors[1]?.incomeSalaryPeriod &&
        simulation.profileMortgagors[1]?.incomeSalaryTaxes &&
        simulation.profileMortgagors[1]?.incomeSalaryAmount
    )

  const [activeField, setActiveField] = useActiveField(getDefaultActiveField(), getIsFilled())

  if (!simulation.profileMortgagors[1]) {
    return null
  }

  const saveAmount = (value: string) => {
    setSimulationComortgagor({ incomeSalaryAmount: toIntOrNull(value) })
  }

  const isSalaryHigherThan25K = (salary: number | null) => {
    if (!salary) return false
    return salary > 25_000
  }

  return (
    <TrackedView events={{ eventName: EventName.PageViewed, eventPayload: { page: 'salary-comortgagor' } }}>
      <Title>{t('sentences.income.comortgagor.title')}</Title>
      <div>
        {simulation.profileMortgagors[1].occupationKind === SimulationMortgagorOccupationKind.Other ||
        simulation.profileMortgagors[1].occupationKind === SimulationMortgagorOccupationKind.Medical
          ? t('sentences.income.comortgagor.otherIncome.sentence.0')
          : t('sentences.income.comortgagor.salary.sentence.0')}
        <Prompt onClick={() => setActiveField(0)} isFilled={!!simulation.profileMortgagors[1].incomeSalaryPeriod}>
          {t(
            `sentences.income.period.${
              simulation.profileMortgagors[1].incomeSalaryPeriod || SimulationMortgagorIncomePeriod.Yearly
            }`
          )}
        </Prompt>
        {activeField > 0 && (
          <Prompt onClick={() => setActiveField(1)} isFilled={!!simulation.profileMortgagors[1].incomeSalaryTaxes}>
            {t(
              `sentences.income.type.${
                simulation.profileMortgagors[1].incomeSalaryTaxes || SimulationMortgagorIncomeTaxes.After
              }`
            )}
          </Prompt>
        )}
        {activeField > 1 && (
          <>
            {simulation.profileMortgagors[1].occupationKind === SimulationMortgagorOccupationKind.Other ||
            simulation.profileMortgagors[1].occupationKind === SimulationMortgagorOccupationKind.Medical ? (
              <>
                {t('sentences.income.comortgagor.otherIncome.sentence.1')}
                {simulation.profileMortgagors[1].occupationOtherStatus ===
                  SimulationMortgagorOccupationOtherStatus.BusinessOwner &&
                  t('sentences.income.comortgagor.otherIncome.sentence.2')}
              </>
            ) : (
              t('sentences.income.comortgagor.salary.sentence.1')
            )}
            <TrackedResizableField
              clearable
              events={{
                eventName: EventName.SimulationFieldChanged,
                eventPayload: { field: 'coMortgagorIncomeSalaryAmount' },
              }}
              inputProps={{
                autoFocus: activeField === 2,
                placeholder:
                  simulation.profileMortgagors[1].incomeSalaryPeriod === SimulationMortgagorIncomePeriod.Yearly
                    ? '22000'
                    : '1800',
                type: 'integer',
              }}
              onChange={saveAmount}
              value={simulation.profileMortgagors[1].incomeSalaryAmount?.toString() || ''}
            />
            €
          </>
        )}
        .
      </div>

      {activeField === 0 && (
        <Choices
          choices={mapIncomePeriodValues(
            [SimulationMortgagorIncomePeriod.Yearly, SimulationMortgagorIncomePeriod.Monthly],
            context
          )}
          events={{ eventName: EventName.SimulationChoiceClicked, eventPayload: { choice: 'coMortgagorIncomePeriod' } }}
          onChoiceClick={() => setActiveField(1)}
        />
      )}

      {activeField === 1 && (
        <Choices
          choices={mapIncomeTypeValues(
            Object.values([SimulationMortgagorIncomeTaxes.After, SimulationMortgagorIncomeTaxes.Before]),
            context
          )}
          events={{ eventName: EventName.SimulationChoiceClicked, eventPayload: { choice: 'coMortgagorIncomeType' } }}
          onChoiceClick={() => setActiveField(2)}
        />
      )}

      {activeField > 1 &&
        isIncomeSalaryPeriodMonthly &&
        isSalaryHigherThan25K(simulation.profileMortgagors[1]?.incomeSalaryAmount ?? null) && (
          <Message
            html={t('sentences.tips.income.salary.amount.mortgagor', {
              annualSalary: ((simulation.profileMortgagors[1]?.incomeSalaryAmount ?? 0) * 12).toLocaleString('fr'),
            })}
          />
        )}

      {isBusinessOwner && <Message>{t('sentences.tips.income.businessOwner.comortgagor')}</Message>}

      {activeField > 1 &&
        simulation.profileMortgagors[1].incomeSalaryTaxes === SimulationMortgagorIncomeTaxes.After && (
          <Message html={t('sentences.tips.income.salary.tax.comortgagor')} />
        )}

      {activeField >= 2 && !!simulation.profileMortgagors[1].incomeSalaryAmount && (
        <ButtonNext>{t('sentences.next')}</ButtonNext>
      )}
    </TrackedView>
  )
}
